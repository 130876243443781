:root {
	--app-height: 100%;
}

/* TODO: refactor to have base font size 16px since 
* it is impossible for user to see text that is 8px (62.5%) on any screen 
*/

html {
	height: var(--app-height);
	width: 100%;
	box-sizing: border-box;
	font-size: 62.5%;
}

@media (max-width: 250px) {
	html {
		font-size: 30%;
	}
}

@media (max-width: 768px) {
	html {
		font-size: 50%;
	}
}

@media (min-width: 2000px) and (max-width: 2800px) {
	html {
		font-size: 80%;
	}
}

@media (min-width: 2800px) {
	html {
		font-size: 110%;
	}
}

*,
*:after,
*:before {
	box-sizing: inherit;
}

body {
	margin: 0;
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 300;
	src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 500;
	src: local('Poppins'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 700;
	src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
	font-family: 'dotsfont';
	src: local('dotsfont'), url(./fonts/dotsfont.ttf) format('truetype');
}

@font-face {
	font-family: 'MiriamLibre';
	font-weight: 500;
	src: local('MiriamLibre'), url(./fonts/MiriamLibre-Bold.ttf) format('truetype');
}

/* Global style for Mui Autocomplete & TextField*/
.MuiAutocomplete-input {
	font-size: 1.4rem !important;
}

.MuiAutocomplete-inputRoot {
	font-size: 1.4rem !important;
}

.MuiAutocomplete-option {
	font-size: 1.4rem;
}

.MuiChip-label {
	font-size: 1.2rem !important; 
  }

.MuiAutocomplete-popupIndicator svg {
	font-size: 2.5rem !important; 
}

.MuiTextField-root {
	font-size: 1.4rem !important;
}

.MuiInputLabel-root {
	font-size: 1.4rem !important;
}

.MuiInputBase-input {
	font-size: 1.4rem !important; 
}